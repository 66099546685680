import React from "react";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import CareersForm from "../../components/CareersForm/CareersForm";
import Footer from "../../components/Home/Footer";
import PageMain from "../../components/PageMain";
import GetAQuote from "../../components/GetAQuote";

const Careers = () => {
  return (
    <>
      <ServicesNavbar />
      <PageMain
        title="Careers"
        main={{
          style: {
            backgroundImage: "url(/images/Careers/CareerImgBanner.webp)",
            backgroundSize: "cover",
          },
        }}
        introduction={[]}
      />
      <CareersForm />
      <Footer />
      <GetAQuote/>
    </>
  );
};

export default Careers;
