import React, { useRef, useState } from "react";
import Modal from "../Modal/Modal";
import Captcha from "../Captcha/index";
import emailjs from "emailjs-com";

const GetAQuoteModal = ({ setShowModal, showModal }) => {
  const form = useRef();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phone_number: "",
    email: "",
    message: "",
  });

  const handleModalClose = () => {
    setShowModal(false);
    setFormData({
      name: "",
      phone_number: "",
      email: "",
      message: "",
    });
    setFormSubmitted(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
    .sendForm(
      "service_sg3268a", 
      "template_ohpqgqe", 
      form.current,
      "kP187BtBIud6a_HWj"
    )
      .then(
        () => {
          setFormSubmitted(true);
        },
        (error) => {
          console.error("EmailJS Error:", error);
        }
      );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "phone_number" ? value.replace(/\D/g, "") : value,
    });
  };

  return (
    <>
      {showModal && (
        <div
          className="position-fixed top-0  w-100 "
          style={{
            height: "100vh",
            left: 0,
            background: "rgba(0,0,0,0.5)",
            zIndex: 111,
          }}
          onClick={() => handleModalClose()}
        ></div>
      )}

      <Modal show={showModal} handleClose={handleModalClose}>
        {!formSubmitted ? (
          <div
            className="container-fluid container-g-q"
            style={{ padding: "25px" }}
          >
            <div className="row">
              <h4
                style={{
                  color: "white",
                  fontWeight: "800",
                }}
              >
                Get A Quote
              </h4>
            </div>
            <form
              className="form-container-g-q"
              onSubmit={handleSubmit}
              ref={form}
              method="post"
            >
              <div className="row">
                {/* Name */}
                <div className="col-lg-6  mb-2">
                  <div className="custom-input-group">
                    <label htmlFor="name" className="form-label">
                      Name*
                    </label>
                    <input
                      type="text"
                      className="input-style"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Enter first and last name"
                      required
                    />
                  </div>
                </div>
                {/* Phone Number */}
                <div className="col-lg-6 mb-2">
                  <div className="custom-input-group">
                    <label htmlFor="phone_number" className="form-label">
                      Phone Number*
                    </label>
                    <input
                      type="text"
                      className="input-style"
                      id="phone_number"
                      name="phone_number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      placeholder="Eg. 123-456-7890"
                      required
                    />
                  </div>
                </div>
              </div>
              {/* Email */}
              <div className="row">
                <div className="col-lg-12 col-md-12 mb-2">
                  <div className="custom-input-group">
                    <label htmlFor="email" className="form-label-g-q">
                      Email*
                    </label>
                    <input
                      type="email"
                      className="form-control-g-q transparent-input-g-q"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Eg. youremail@example.com"
                      required
                    />
                  </div>
                </div>
              </div>
              {/* Message */}
              <div className="row ">
                <div className="col-lg-12 col-md-12">
                  <div className="custom-input-group mb-2">
                    <label htmlFor="message" className="form-label-g-q mb-2">
                      Message*
                    </label>
                    <textarea
                      className=" "
                      id="message"
                      name="message"
                      rows={5}
                      value={formData.message}
                      onChange={handleInputChange}
                      required
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="row ">
                <div className="col-lg-12 col-md-12">
                  <div className="custom-input-group mb-2">
                    <Captcha />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn   get-a-quote-btn">
                    Get A Quote
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="thank-you-message">
            <h4
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: 800,
                padding: "30px 10px 30px 10px",
              }}
            >
              Thank you for your enquiry!
            </h4>
          </div>
        )}
      </Modal>
    </>
  );
};

export default GetAQuoteModal;