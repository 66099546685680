import React, { useRef, useState } from "react";
import "./../../css/Form.scss";
import Captcha from "../Captcha/index";
import emailjs from "emailjs-com";

const Form = () => {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === "phone_number" ? value.replace(/\D/g, "") : value,
    }));
  };

  const handleReset = () => {
    setIsSubmitted(false);
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        "service_sg3268a", 
        "template_ohpqgqe", 
        form.current,
        "kP187BtBIud6a_HWj"
      )
      .then(() => {
        setIsSubmitted(true);
      })
      .catch(() => {
        console.log("Error occurred");
      });
  };

  return (
    <div className="container-fluid split-form-container pt-0">
      <div className="split-form-image"></div>
      {isSubmitted ? (
        <div className="thank-you-message-main-form split-form">
          <h3
            className="thank-you-message-main-form-one ZoomIn"
            style={{ color: "#fff", fontWeight: "800", paddingTop: "50px" }}
          >
            Thank you for submitting your enquiry!
          </h3>
          <div className="text-center">
            <button
              type="button"
              className="btn get-a-quote-btn my-4"
              onClick={handleReset}
            >
              Close
            </button>
          </div>
        </div>
      ) : (
        <div className="split-form" style={{ padding: "35px" }}>
          <div className="form-container">
            <div className="w-100">
              <h2>How can</h2>
              <h4 className="text-white sub-title">We Help You?</h4>
            </div>
            <form ref={form} onSubmit={handleSubmit} method="post">
              <div className="mb-3">
                <label htmlFor="name" className="form-label">
                  Name*
                </label>
                <input
                  type="text"
                  className="form-control transparent-input"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={{ color: "white" }}
                  placeholder="Enter first and last name"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email*
                </label>
                <input
                  type="email"
                  className="form-control transparent-input"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  style={{ color: "white" }}
                  placeholder="Eg. youremail@email.com"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="phone_number" className="form-label">
                  Phone Number*
                </label>
                <input
                  type="text"
                  className="form-control transparent-input"
                  id="phone_number"
                  name="phone_number"
                  value={formData.phone_number}
                  onChange={handleChange}
                  style={{ color: "white" }}
                  placeholder="Enter 10-digit mobile number"
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message" className="form-label">
                  Message*
                </label>
                <textarea
                  style={{ minHeight: "150px", color: "white" }}
                  className="form-control transparent-input"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="mb-3">
                <Captcha />
              </div>
              <button type="submit" className="btn get-a-quote-btn">
                Get A Quote
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Form;