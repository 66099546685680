// SlidePrevButton.jsx
import React from "react";

const SlidePrevButton = ({ onClick }) => { // Change the component name
  return (
    <button
      type="button"
      className="outline-none service-button me-3"
      onClick={onClick}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

export default SlidePrevButton; // Change the export name
