import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMediaQuery } from "react-responsive";

const Captcha = () => {
  const container = useRef(null);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 750px)" });
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (container.current) {
        setContainerWidth(container.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [container.current]);

  return (
    <div ref={container}>
      <div>
             <ReCAPTCHA
        style={{
          transform: `${isSmallScreen ? "translateX(-30px)" : "translateX(0px)"}`,
          margin: "5px auto", 
          transformOrigin: "left center", 
          transform: `scale(${isSmallScreen ? containerWidth / 304 : 1})`,
        }}
        sitekey={process.env.REACT_APP_SITE_KEY}
      /> 
      </div>
    </div>
  );
};

export default Captcha;
