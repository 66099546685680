import React from "react";
import "./AboutStorySection.css";

function AboutStorySection() {
  return (
    <div className="container aboutnew-story-bg">
      <div className="row">
        <div className="col-lg" style={{ padding: "20px" }}>
          <img
            src="../../../images/Achievement/Achievements01.webp"
            style={{
              width: "100%",
              border: "13px solid #fff",
            }}
            alt="Our Achievements" 
            loading="lazy" 
          />
        </div>
        <div className="col-lg d-flex align-items-center justify-content-left aboutnew-story-text">
          <div>
            <h2>Our Story</h2>
            <p>
              Founded in Oman in 2008 by Mr. Salim Ahmed Mohammed Al Badi, ALSI
              has evolved into a dynamic force in the logistics industry. Our
              commitment to excellence has been recognized with consecutive
              'Best Customs Clearance Company' awards from Oman Customs in 2021
              and 2022, as well as the 'Smart Workplace Award' from Dubai
              Customs in 2022.
            </p>
            <p>
              With over 130+ professionals, we process an average of 9,000+ Customs Declarations
              monthly. Operating from 10 strategic locations in Oman, alongside
              offices in the UAE, Qatar, and Saudi Arabia, our round-the-clock
              operations ensure seamless service. We take pride in our ISO
              9001:2015 certification, which highlights our commitment to
              quality. ALSI is also a registered member of WCA and the FIATA
              Freight Network, extending our global reach.
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 aboutnew-story-text">
          <p className="px-2" style={{ paddingBottom: "20px" }}>
            Our comprehensive services encompass Customs Clearance, Freight
            Forwarding, Project Cargo Solutions, and efficient GCC and Domestic
            Transportation. Our journey is a testament to unwavering dedication,
            forging enduring partnerships, and consistently delivering
            exceptional logistics solutions. At ALSI, our story embodies growth,
            innovation, and an unwavering pursuit of excellence across all
            aspects of logistics.
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutStorySection;
