import React, { useState, useEffect } from "react";
import "./../../css/Blog.css";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import blogImg16 from "../../images/BlogBanner/BlogBanners/EmergencyResponsePlanningPreparingforIncidentsinHazmattransport.webp";
import blogImg17 from "../../images/BlogBanner/BlogBanners/TheRightEquipmentfortheJobBenefitsofSpecialEquipmentHireandRental.webp";
import blogImg18 from "../../images/BlogBanner/BlogBanners/Legalisation.webp";
import blogImg19 from "../../images/BlogBanner/BlogBanners/Transitclearanceprocessforefficientcross-bordershipmentsandcustomscontrol.webp";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className=" outline-none   me-4 service-button"
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
      onClick={() => swiper.slidePrev()}
    >
      <i class="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none  service-button"
      onClick={() => swiper.slideNext()}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i class="bi bi-arrow-right"></i>
    </button>
  );
};

function Blog() {
  const cardData = [
    {
      imageUrl: blogImg19,
      heading:
        "Transit Clearance Explained: What You Need to Know for Cross-Border Shipments",
      content:
        "When moving goods across multiple countries, one of the essential processes that must be managed carefully is transit clearance. Transit clearance is the procedure through which goods",
      link: "/market-updates/19",
    },
    {
      imageUrl: blogImg18,
      heading:
        "Documentation Services in Customs Clearance: Why Legalisation and Attestation Matter",
      content:
        "In the world of global trade, documentation is key to ensuring smooth and compliant movement of goods across borders. For any shipment, especially those involving multiple countries or specialized goods",
      link: "/market-updates/18",
    },
    {
      imageUrl: blogImg17,
      heading:
        "The Right Equipment for the Job: Benefits of Special Equipment Hire and Rental",
      content:
        "At ALSI Global, we understand the dynamic nature of today's business landscape. Projects often require a diverse range of specialized equipment, and owning and maintaining",
      link: "/market-updates/17",
    },
    {
      imageUrl: blogImg16,
      heading:
        "Emergency Response Planning: Preparing for Incidents in Hazmat Transport",
      content:
        "Handling hazardous cargo involves significant risks. We are at ALSI Global, as a leading logistics company in Oman and in the GCC",
      link: "/market-updates/16",
    }
  ];

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);

  const cardStyle = {
    width: "100%",
    padding: "10px",
    border: "none",
    borderRadius: "0px",
  };

  return (
    <>
      <div className=" blogs-bg-main-bg d-none d-lg-block">
        <div className="col-lg-12">
          <h1>Market Updates</h1>
        </div>
        <div className="container container-sm container-md container-lg container-xl container-xxl">
          <div className="row d-flex justify-content-center">
            {cardData.map((card, index) => (
              <div className="col-md-3 mb-4" key={index}>
                <div className="market-blog-card card p-2 h-100">
                  <img
                    src={card.imageUrl}
                    className="w-100"
                    style={{
                      height: "250px",
                      borderRadius: "6px",
                      objectFit: "cover",
                    }}
                    alt={`Blog ${index + 1}`}
                    loading="lazy"
                  />
                  <div className="px-2 mt-3 d-flex justify-content-between flex-column h-50">
                    <div className="card-title-wrapper">
                      <p
                        className="card-title fw-bold"
                        style={{ fontSize: "15px" }}
                      >
                        {card.heading}
                      </p>
                      <p className="m-0" style={{ fontSize: "13px" }}>
                        {card.content}
                      </p>
                    </div>
                    <div>
                      <div className="d-flex ">
                        <Link
                          to={card.link}
                          style={{
                            color: "rgb(25, 51, 119)",
                          }}
                          className={`d-block py-2 text-decoration-none  fw-semibold`}
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <a href="/market-updates" className="view-all-blogs">
              View More
              <div className="ms-3 icon-arrow">
                <i className="bi bi-arrow-right"></i>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className="blogs-bg-main-bg d-block d-lg-none">
        <div className="col-lg-12">
          <h1>Market Updates</h1>
        </div>
        <div className="container container-sm container-md container-lg container-xl container-xxl">
          <Swiper
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            navigation={false}
            onSwiper={(swiper) => setSwiper(swiper)}
            onTouchStart={() => {
              if (swiper && swiper.autoplay && swiper.autoplay.running) {
                swiper.autoplay.stop();
              }
            }}
            onTouchEnd={() => {
              if (swiper && swiper.autoplay && !swiper.autoplay.running) {
                swiper.autoplay.start();
              }
            }}
          >
            {cardData.map((data, index) => (
              <SwiperSlide key={index}>
                <div className={`col-12 col-lg-3 `} key={index}>
                  <div className={`card p-2 h-100 w-100`} style={cardStyle}>
                    <img
                      src={data.imageUrl}
                      className="w-100"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "0px",
                      }}
                      alt={`Blog ${index + 1}`}
                      loading="lazy"
                    />
                    <div className="px-2 mt-3 d-flex flex-column">
                      <div className="card-title-wrapper">
                        <p className="card-title fw-bold">{data.heading}</p>
                        <p className="m-0" style={{ fontSize: "13px" }}>
                          {data.content}
                        </p>
                      </div>
                      <div className="py-2">
                        <Link
                          to={data.link}
                          style={{
                            color: "rgb(25, 51, 119)",
                          }}
                          className="d-block py-2 text-decoration-none  fw-semibold"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="w-100 d-flex justify-content-center align-items-center mt-4">
              <SlidePrevButton />
              <SlideNextButton />
            </div>
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default Blog;
