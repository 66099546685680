import React from "react";

const SlideNextButton = ({ onClick }) => { 
  return (
    <button
      type="button"
      className="outline-none service-button"
      onClick={onClick}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

export default SlideNextButton;
