import React from "react";
import { useParams } from "react-router-dom";
import data from "../../data.json";
import CardTile from "../../components/CardTiles";
import PageMain from "../../components/PageMain";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import OtherServices from "../../components/Services/OtherServices";
import Industries from "../../components/Home/Industries";
import Form from "../../components/Home/Form";
import Footer from "../../components/Home/Footer";
import ImageCard from "../../components/ImageCards";
import "./service.scss";
import GetAQuote from "../../components/GetAQuote";

const getServiceType = (type) => {
  switch (type) {
    case "customs_clearance":
      return "Customs Clearance";
    case "road_freight":
      return "Road Freight";
    case "ocean_freight":
      return "Ocean Freight";
    case "air_freight":
      return "Air Freight";
    case "project_cargo_services":
      return "Project Cargo Services";
    case "vessel_agency":
      return "Vessel Agency";
    case "warehousing_and_distribution":
      return "Warehousing and Distribution";
    case "specialized_services":
      return "Specialized Services";
    case "ship_spare_logistics":
      return "Ship Spare Logistics";
    case "event_logistics":
      return "Event Logistics";
    case "civil_defense_approved_transportation":
      return "Civil Defense Approved Transportation";
    default:
      return "Customs Clearance";
  }
};

const getComponents = (type, props) => {
  switch (type) {
    case "tile":
      return <CardTile {...props} cardsPerRow={3} cardCount={4} />;
    case "imageCards":
      return <ImageCard {...props} />;
    default:
      return "";
  }
};

const Service = () => {
  const { type, dedicated } = useParams();
  const serviceType = getServiceType(
    dedicated ? (dedicated !== "" ? dedicated : type) : type
  );
  const { header, introduction } = data[serviceType];
  const cardsKeys = Object.keys(data[serviceType]).filter((key) =>
    key.includes("cards")
  );
  const getProps = (type) => {
    switch (type) {
      case "tile":
        return { cards: cardsKeys.map((key) => data[serviceType][key]) };
      case "imageCards":
        return { cards: data[serviceType].cards };
      default:
        return "";
    }
  };

  return (
    <>
      <ServicesNavbar />
        <PageMain
          title={header.heading}
          introduction={introduction}
          main={{
            style: {
              backgroundImage: `url(${header.image})`,
              backgroundSize: "cover",
              backgroundPosition: "50%",
              height: "450px",
            },
          }}
        />
        <div className={`specialised-service`}>
          {getComponents(
            data[serviceType].type,
            getProps(data[serviceType].type)
          )}
        </div>
        <OtherServices cards={data[serviceType].otherServices} />
        <Industries />
        <Form />
        <Footer />
        <GetAQuote/>
    </>
  );
};

export default Service;
