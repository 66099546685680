import React, { useEffect, useState } from "react";
import PageMain from "../../components/PageMain";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import data from "../../marketUpdate.json";
import { useParams } from "react-router-dom";
import Blog from "../../components/Blog";
import Form from "../../components/Home/Form";
import Footer from "../../components/Home/Footer";
import Style from "./market.module.scss";
import { Tooltip } from "react-tooltip";

const MarketUpdatePost = () => {
  const { type } = useParams();
  const [isTooltip, setIsTooltip] = useState(false);
  let index = `blog_${Number(type)}`;

  const post = data[index] || {};
  const postDate = post.date || "";
  const postTime = post.time || "";

  const share = async () => {
    try {
      await navigator.share({ text: window.location.href });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isTooltip) {
      setTimeout(() => {
        setIsTooltip(false);
      }, 3000);
    }
  }, [isTooltip]);

  return (
    <div>
      <ServicesNavbar />
      <PageMain
        title={data[index]?.header?.text}
        main={{
          style: {
            position: "relative",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${data[index]?.header?.image})`,
            backgroundSize: "cover",
          },
        }}
        mainRender={() => (
          <div
            className="position-absolute bottom-0 container container-sm container-md container-lg container-xl container-xxl d-none d-lg-block"
            style={{
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <div className="row w-100 mx-auto text-white">
              <div className="col-lg-6">
                <p>{postDate}, {postTime}</p>
              </div>
              <div className="col-lg-6  d-flex justify-content-end align-items-center">
                <a
                  href="https://www.facebook.com/alsigloballlc/"
                  className="ms-3"
                  target="_blank"
                >
                  <img
                    className={Style["mu-header-icons"]}
                    src="/images/facebook-white.webp"
                    alt="Facebook Icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/alsiglobal/"
                  className="ms-3"
                  target="_blank"
                >
                  <img
                    className={Style["mu-header-icons"]}
                    src="/images/instagram-white.webp"
                    alt="Instagram Icon"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/alsi-official/"
                  className="ms-3"
                  target="_blank"
                >
                  <img
                    className={Style["mu-header-icons"]}
                    src="/images/linkedin-white.webp"
                    alt="LinkedIn Icon"
                  />
                </a>

                <a href="https://twitter.com/ALSI_OFFICIAL/" className="ms-3" target="_blank">
                  <img
                    className={Style["mu-header-icons"]}
                    src="/images/twitter-white.webp"
                    alt="Twitter Icon"
                  />
                </a>

                <button
                  className="p-0 bg-transparent outline-none border-0 ms-3"
                  data-tooltip-content={isTooltip ? "Copied" : "Copy"}
                  data-tooltip-id="blog-tooltip"
                  onClick={() => {
                    setIsTooltip(true);
                    navigator.clipboard.writeText(window.location.href);
                  }}
                >
                  <img
                    className={Style["mu-header-icons"]}
                    src="/images/link-white.webp"
                    alt="Copy Link Icon"
                  />
                </button>

                <button
                  className=" p-0 bg-transparent outline-none border-0 ms-3"
                  data-tooltip-content={isTooltip ? "Copied" : "Copy"}
                  onClick={share}
                >
                  <img
                    className={Style["mu-header-icons"]}
                    src="/images/more-icon-white.webp"
                    alt="More Icon"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
        introduction={[]}
      />
      <div className={`${Style["blog-wrapper"]}`}>
        <Blog {...data[index]} />
      </div>
      <Form />
      <Footer />
      <Tooltip id="blog-tooltip" />
    </div>
  );
};

export default MarketUpdatePost;
