import React from "react";
import LTRCard from "./LTRCard/LTRCard";
import RTLCard from "./RTLCards";

const ImageCard = ({ cards }) => {
  return (
    <div className="container container-sm container-md container-lg container-xl container-xxl ">
      {cards.map((card) => {
        if (card.flow === "ltr") {
          return <LTRCard {...card} />;
        }
        return <RTLCard {...card} />;
      })}
    </div>
  );
};

export default ImageCard;
