import React from "react";
import "../../css/AboutCss/AboutTeam.scss";

const AboutTeam = () => {
  return (
    <>
      {/* <div className=" about-bg-team mb-4 d-none">
        <div className="container container-sm container-md container-lg container-xl container-xxl">
          <div className="row">
            <h1 className="about-team-head">Our Team</h1>
            <p className="team-parag-team text-center"></p>
          </div>

          <div className="row text-center about-team-clr">
            <div className="col-lg-4">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                className="team-members-team"
                loading="lazy"
              />
              <h5>Name</h5>
              <p>Founder & Chairman</p>
            </div>
            <div className="col-lg-4">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                className="team-members-team"
                loading="lazy"
              />
              <h5>Name</h5>
              <p>Chief Operating Officer</p>
            </div>
            <div className="col-lg-4">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                loading="lazy"
                className="team-members-team"
              />
              <h5>Name</h5>
              <p>General Manager - Oman</p>
            </div>

            <div className="col-lg-4">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                loading="lazy"
                className="team-members-team"
              />
              <h5>Name</h5>
              <p>Commercial Director</p>
            </div>
            <div className="col-lg-4 about-team-clr-one">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                loading="lazy"
                className="team-members-team"
              />
              <h5>Name</h5>
              <p>Country Manager - UAE </p>
            </div>
            <div className="col-lg-4 about-team-clr-one team-about-pad">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                loading="lazy"
                className="team-members-team"
              />
              <h5>Name</h5>
              <p>Branch Manager - Qatar </p>
            </div>
            <div className="col-lg-4 about-team-clr-one team-about-pad">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                loading="lazy"
                className="team-members-team"
              />
              <h5>Name</h5>
              <p>Operations Manager, KSA </p>
            </div>
            <div className="col-lg-4 about-team-clr-one team-about-pad">
              <img
                src="/images/AboutTeam/chief.webp"
                alt="Team Member"
                loading="lazy"
                className="team-members-team"
              />
              <h5>Name</h5>
              <p>Regional Sales Manager </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* new */}
      <div className="container-fluid about-team-new">
        <div className="row">
          <h1 className="about-team-head pb-2">Our Team</h1>
        </div>

        <div
          className="row text-center team-container-new"
          style={{ padding: "30px" }}
        >
          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Salim Ahmed Mohammed Al Badi</h5>
                <p>Founder & Chairman</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Syamkumar Kulangara</h5>
                <p>Chief Operating Officer</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Mohammed Al Badi</h5>
                <p>General Manager - Oman</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Jissen Mathew John</h5>
                <p>Commercial Director</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Marc Prasad
                </h5>
                <p>Regional Vice President</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Justin Varghese
                </h5>
                <p>Country Manager – UAE</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Adityan K J</h5>
                <p>Branch Manager - Qatar</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Khalid Al Badi</h5>
                <p>Operations Manager, KSA</p>
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
            <div className="team-border pt-2 d-flex flex-column align-items-center">
              <div className="my-2 team-name-position">
                <h5>Gokul Gopinath</h5>
                <p>Regional Sales Manager</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutTeam;
