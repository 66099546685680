import React from "react";
import Style from "../imagecards.module.scss";
import { Link } from "react-router-dom";

const LTRCard = ({
  title,
  description,
  rightPane = {},
  fullPane,
  leftPane,
  image,
  readMore,
  container,
}) => {
  return (
    <div
      className={`row image-cards-container w-100 mx-auto ${Style["section-container"]}`}
      {...container}
    >
      <div
        className={`col-lg-6 image-cards-left ${Style["image-div"]}`}
        {...leftPane}
        style={{
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          ...leftPane?.style,
        }}
      ></div>
      <div
        className={`col-lg-6 ${Style["content-container"]} image-cards-right`}
        {...rightPane}
      >
        <div className={`${Style["content"]}`}>
          <h1 className="mb-3">{title}</h1>
          <div>
            {description.map((desc, index) => (
              <p className="" key={index}>
                {desc}
              </p>
            ))}
          </div>
          {readMore && readMore !== "" && (
            <div>
              <Link
                to={readMore}
                className={`d-flex  align-items-center ${Style["read-more-ltr"]}  `}
                style={{ color: "inherit", textDecoration: "none" }}
              >
                Learn more
                <div
                  className={` d-flex justify-content-center align-items-center ms-3 ${Style["icon-arrow"]}`}
                >
                  <i className="bi bi-arrow-right "></i>
                </div>
              </Link>
            </div>
          )}
        </div>
      </div>
      {fullPane && (
        <div {...fullPane} className="about-story-parag-second">
          {fullPane.description}
        </div>
      )}
    </div>
  );
};

export default LTRCard;
