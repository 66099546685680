import React from "react";
import "./../../css/NotFound/NotFound.css";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";

function NotFound() {
  return (
    <div className=" not-found-bg">
      <div className="not-found-head">
        <p style={{ fontSize: "30px" }}>Oops! Page Not Found</p>
        <div />

        <div className="not-found-fnf">
          <h2 style={{ fontSize: "130px", fontWeight: "800" }}>404</h2>
        </div>

        <div className="not-found-exist">
          <p style={{ fontSize: "16px" }}>
            Sorry!! The Page You Are Looking For Does Not Exist
          </p>
        </div>

        <div className="not-found-button">
          <Link to="/">
            <Button className="not-found-button-size" variant="primary">
              Back To Home Page
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
