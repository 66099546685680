import React, { useState, useEffect } from "react";
import "../../css/Differentiators.css";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/swiper-bundle.css";

const SlidePrevButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className=" outline-none me-4 service-button"
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
      onClick={() => swiper.slidePrev()}
    >
      <i className="bi bi-arrow-left"></i>
    </button>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();
  return (
    <button
      type="button"
      className="outline-none  service-button"
      onClick={() => swiper.slideNext()}
      style={{
        borderRadius: "50%",
        border: "1px solid #00000052",
        padding: "6px 10px",
      }}
    >
      <i className="bi bi-arrow-right"></i>
    </button>
  );
};

const Differentiators = (props) => {
  const items = [
    {
      image: "images/Differentiators/iteration.webp",
      title: "AGILE",
    },
    {
      image: "images/Differentiators/critical.webp",
      title: "CRITICAL LOGISTICS EXPERTS",
    },
    {
      image: "images/Differentiators/customer.webp",
      title: "CUSTOMER CENTRIC",
    },
    {
      image: "images/Differentiators/end.webp",
      title: "END-TO-END SERVICES AND SOLUTIONS",
    },
    {
      image: "images/Differentiators/flexible.webp",
      title: "FLEXIBLE",
    },
    {
      image: "images/Differentiators/excellent.webp",
      title: "EXCELLENT CUSTOMER SERVICE",
    },
    {
      image: "images/Differentiators/operational.webp",
      title: "OPERATIONAL EXPERTISE",
    },
    {
      image: "images/Differentiators/value.webp",
      title: "VALUE CREATION ",
    },
    {
      image: "images/Differentiators/round.webp",
      title: "ROUND THE CLOCK SERVICE",
    },
    {
      image: "images/Differentiators/tailor.webp",
      title: "TAILOR-MADE SOLUTIONS",
    },
  ];

  const [swiper, setSwiper] = useState(null);
  const [autoplay, setAutoplay] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      if (swiper && swiper.slideNext) {
        swiper.slideNext();
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [swiper]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const firstRowItems = items.slice(0, 5);
  const secondRowItems = items.slice(5, 10);

  return (
    <div
      className="container diffs-containers"
      style={{ marginBottom: "40px" }}
    >
      <div className="row text-center">
        <p className="feature-text pt-4"></p>
        <h1 className="differentiators-header">Key Differentiators</h1>
        <p className="differentiators-description pb-5">
          ALSI stands out through a combination of expertise, reliability, and
          flexibility. Our deep industry knowledge, commitment to meeting
          deadlines, and adaptability to changing market dynamics make us the
          preferred choice for all your logistics needs. We're not just a
          service provider; we're your trusted partner in streamlining your
          supply chain and achieving your business goals.
        </p>
      </div>

      {/* First Row (visible on larger screens) */}
      <div className="row differentiators-row-one d-none d-lg-flex">
        {firstRowItems.map((item, index) => (
          <div className="col-lg text-center" key={index}>
            <div className="diff-img-setup">
              <div className="diffs-one">
              <img
                  src={item.image}
                  className="differentiators-img"
                  alt={`Differentiator ${index + 1}`} 
                  loading="lazy" 
                />
              </div>
            </div>
            <h6 className="diff-sem-parag">{item.title}</h6>
          </div>
        ))}
      </div>

      {/* Second Row (visible on larger screens) */}
      <div className="row differentiators-row d-none d-lg-flex">
        {secondRowItems.map((item, index) => (
          <div className="col-lg text-center" key={index}>
            <div className="diff-img-setup">
              <div className="diffs-one">
              <img
                  src={item.image}
                  className="differentiators-img"
                  alt={`Differentiator ${index + 6}`} 
                  loading="lazy"
                />
              </div>
            </div>
            <h6 className="diff-sem-parag">{item.title}</h6>
          </div>
        ))}
      </div>

      {/* Items for smaller screens */}
      <div className="container">
        <div className="row differentiators-row d-flex d-lg-none">
          <Swiper
            className="h-100"
            spaceBetween={0}
            slidesPerView={1}
            centeredSlides={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            loop={true}
            navigation={false}
            breakpoints={{
              768: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
            onSwiper={(swiper) => setSwiper(swiper)}
            onTouchStart={() => {
              setAutoplay(false);
            }}
            onTouchEnd={() => {
              setAutoplay(true);
            }}
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="diff-img-setup d-flex justify-content-center align-items-center flex-column"
                  style={{ width: "100%" }}
                >
                  <div className="diffs-one">
                  <img
                      src={item.image}
                      alt={`Differentiator ${index + 1}`}
                      loading="lazy"
                    />
                  </div>
                  <h6 className="diff-sem-parag text-center mt-3">
                    {item.title}
                  </h6>
                </div>
              </SwiperSlide>
            ))}
            <div className="w-100 d-flex justify-content-center align-items-center">
              <SlidePrevButton />
              <SlideNextButton />
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Differentiators;
