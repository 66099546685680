import React from "react";
import styles from "./../../css/Footer.module.scss";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const omanPhoneNumbers = "+968 2675 5598, +968 2675 5550";
  const uaePhoneNumbers = "+971 4 5850934, +971 50 1074170";
  const qatarPhoneNumbers = "+974 4047 8563, +974 3362 5333";
  const saudiArabiaPhoneNumber = "+966 050 362 5981";

  return (
    <>
      <div className={`container`}>
        <div className={`row p-md-0 ${styles["center-content"]}`}>
          <div
            className={`col-lg-12 text-center ${styles["footer-head-logo"]} mb-5`}
          >
            <img
              src="/images/nav_logo.webp"
              alt="ALSI Global"
              loading="lazy"
            />
          </div>
          <div className="col-lg-3 mb-4" style={{ lineHeight: "30px" }}>
            <h6 className="center-content-fh">Sultanate of Oman</h6>
            <p className="center-content-fh">
              Alsi Global LLC
              <br />
              P.O.Box 608. P.C: 322.
              <br />
              W. Sohar, Sultanate Of Oman.
              <br />
              <a href={`tel:${omanPhoneNumbers.replace(/, /g, ',')}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                {omanPhoneNumbers}
              </a>
              <br />
              <a href="mailto:info@alsiglobal.com" style={{ color: 'inherit', textDecoration: 'none' }}>
                info@alsiglobal.com
              </a>
            </p>
          </div>
          <div className="col-lg-3 mb-4" style={{ lineHeight: "30px" }}>
            <h6>United Arab Emirates</h6>
            <p>
              Alsi Global LLC
              <br />
              Dubai National Insurance Building
              <br />
              5th Floor, Office 502, P.O. Box: 392585,
              <br />
              Diera, Dubai, UAE
              <br />
              <a href={`tel:${uaePhoneNumbers.replace(/, /g, ',')}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                {uaePhoneNumbers}
              </a>
            </p>
          </div>
          <div className="col-lg-3 mb-4" style={{ lineHeight: "30px" }}>
            <h6>State of Qatar</h6>
            <p>
              C12 AL Emadi Business Center
              <br />
              2nd Floor, Office 35,
              <br />
              P.O. Box: 30611,
              <br />
              Doha, Qatar.
              <br />
              <a href={`tel:${qatarPhoneNumbers.replace(/, /g, ',')}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                {qatarPhoneNumbers}
              </a>
            </p>
          </div>
          <div className="col-lg-3 mb-4" style={{ lineHeight: "30px" }}>
            <h6>Kingdom of Saudi Arabia</h6>
            <p>
              Building No. 3475, Al Tail Street,
              <br />
              9080, Dhahrat Laban Dist,
              <br />
              PC 12564, Riyadh,
              <br />
              Kingdom of Saudi Arabia
              <br />
              <a href={`tel:${saudiArabiaPhoneNumber}`} style={{ color: 'inherit', textDecoration: 'none' }}>
                {saudiArabiaPhoneNumber}
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className={`row ${styles["footerline-img"]}`}>
        <div className="col-lg-12 text-center p-3">
          <p className={`footer-rights m-0`}>
            © ALSI Global LLC {currentYear}, All Rights Reserved.
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
