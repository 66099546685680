import React from "react";
import ServicesNavbar from "../../components/Services/ServicesNavbar";
import Footer from "../../components/Home/Footer";
import PageMain from "../../components/PageMain";
import galleryImages from "./galleryImages";
import { Link } from "react-router-dom";
import galleryImagesMuscat from "./galleryImagesMuscat";

const cardsData = [
  {
    id: 1,
    image: galleryImages[28],
    title: "Iftar Sohar",
    subtitle: "(2024)",
    link: "/gallery/iftar_sohar",
  },
  {
    id: 2,
    image: galleryImagesMuscat[144],
    title: "Iftar Muscat",
    subtitle: "(2024)",
    link: "/gallery/iftar_muscat",
  },
];

const Gallery = () => {
  return (
    <div>
      <ServicesNavbar />
      <PageMain
        title="Gallery"
        main={{
          style: {
            backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(/images/Gallery/dar62.webp)",
            backgroundSize: "cover",
          },
        }}        
        introduction={[]}
      />
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center", gap: "25px", paddingTop: "60px", paddingBottom: "60px" }}>
        {cardsData.map((card) => (
         <div key={card.id} style={{ maxWidth: "300px" }} className="card-container-gallery">
            <Link to={card.link} style={{ textDecoration: "none", color: "#000" }}>
            <div style={{ border: "0px", borderRadius: "8px", overflow: "hidden", boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.5)"}}>
                <img src={card.image} alt={`Image ${card.id}`} style={{ width: "100%", height: "auto" }} />
                <div style={{ padding: "20px",textAlign: "center" }}>
                  <h2 style={{ margin: "0", fontSize: "18px" , fontWeight: 800  }}>{card.title}</h2>
                  <p style={{ margin: "5px 0", fontSize: "14px"  }}>{card.subtitle}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default Gallery;
