import React from "react";
import styles from "../../css/AboutCss/AboutSimply.module.scss";

const AboutSimply = () => {
  return (
    <>
      <div className={`container container-sm container-md container-lg container-xl container-xxl`}>
        <div className="row" style={{ paddingBottom: "40px" }}>
          <h1 className="display-4 about-simply-head-main text-center">
            Mission, Vision And Purpose
          </h1>
        </div>
        <div className="row">
          <div className={`col-lg-4 mb-4 mb-lg-0`}>
            <div className={`py-2 px-4 ${styles.simplyAboutBg}`}>
              <img
                src="/images/AboutSimply/commitment.webp"
                className={` ${styles.simplyAboutBgOne}`}
                alt="Mission Image"
                loading="lazy"
              />
              <h4 className={`${styles.simplyAboutHeading}`}>Mission</h4>
              <p className={`${styles.simplyAboutParag}`}>
                At ALSI, our mission is to provide logistics solutions that
                simplify operations and empower businesses. We aim to streamline
                the world of logistics, prioritizing customer satisfaction. Our
                solutions-driven approach and commitment to flexibility ensure
                consistently high service levels.
              </p>
            </div>
          </div>

          <div className={`col-lg-4 mb-4 mb-lg-0`}>
            <div className={`py-2 px-4 ${styles.simplyAboutBg}`}>
              <img
                src="/images/AboutSimply/eye.webp"
                className={` ${styles.simplyAboutBgOne}`}
                alt="Vision Image"
                loading="lazy"
                style={{ position: "relative", top: "1px" }}
              />
              <h4 className={`${styles.simplyAboutHeading}`}>Vision</h4>
              <p className={`${styles.simplyAboutParag}`}>
                ALSI envisions leading the logistics industry by setting new
                standards. We aspire to bring reliability, efficiency, and
                seamlessness to the forefront. Our global expansion will be
                complemented by a customer-centric, solutions-driven approach,
                offering flexible services that exceed expectations.
              </p>
            </div>
          </div>

          <div className={`col-lg-4 mb-4 mb-lg-0`}>
            <div className={`py-2 px-4 ${styles.simplyAboutBg}`}>
              <img
                src="/images/OfficesSevicePage/Target.webp"
                className={` ${styles.simplyAboutBgOne}`}
                alt="Purpose Image"
                loading="lazy"
                style={{ position: "relative", bottom: "1px", width: "38%" }}
              />
              <h4 className={`${styles.simplyAboutHeading}`} style={{ position: "relative", bottom: "5px" }}>Purpose</h4>
              <p className={`${styles.simplyAboutParag}`} style={{ position: "relative", bottom: "5px" }}>
                ALSI's purpose is to simplify the world of logistics. We focus
                on delivering solutions that prioritize customers and
                consistently exceed their expectations. By doing so, we
                contribute to a more connected and prosperous global economy,
                leading with reliability, efficiency, and seamlessness as our
                guiding principles.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSimply;
